import React from "react"
import Cart from "../components/Cart"

import SEO from "../components/seo"

const CartPage = () => (
  <>
    <SEO title="Cart" />
    <h1>Cart</h1>
    <Cart />
  </>
)

export default CartPage
